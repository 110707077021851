import React from 'react';
import style from './Player.module.css';

class Player extends React.Component {
    render(){
        return (
            <div className={style.PlayerContainer}>
                <div className={style.Inner}>
                    <div className={style.PlayerContainer2}>
                        <div className={style.PlayerFlex}>
                            <div className={style.Player}>
                                <div className={style.ClosePlayer} onClick={this.props.closePlayer}>×</div>
                                <video controls className={style.Video} playsInline={true}>
                                    {
                                        this.props.videoIndex === 0 ?
                                        <source src="https://campaignsupport.okko.app/resources/apps/34220/media/educational_video.mp4" type="video/mp4"></source> : null
                                    }
                                    {
                                        this.props.videoIndex === 1 ?
                                            <source src="https://campaignsupport.okko.app/resources/apps/34220/media/case_study.mp4" type="video/mp4"></source> : null
                                    }
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Player;
